.main-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.table-container {
  border: 1px solid #000;
  flex: 1;
  margin-bottom: 10px;
  margin-right: 10px;
}

.table-row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #000;
}

.table-cell {
  padding: 8px;
  font-size: small;
  flex: 1;
  
}

.header {
  font-weight: bold;
  background-color: #f2f2f2;
}

.table-cell:not(:last-child) {
  border-right: 1px solid #000;
}

.main-heading{
  color:#0c7fdd;
  text-align: center;
  background-color: #f0f3f7;
}


@media (max-width: 768px) {
  .table-container {
    flex: 100%;
  }

  .table-row {
        display: flex;
         flex-wrap: wrap; 
        border-bottom: 1px solid #000; 
      }
    
      .table-cell {
            padding: 8px;
           font-size: small;
           flex: 1;
          }

    
}


@media (max-width: 1025px) {
  .table-container {
    flex: 0 0 48%;
    margin-right: 1%; 
  }

  .table-row {
    display: flex;
     flex-wrap: wrap; 
    border-bottom: 1px solid #000; 
  }

  .table-cell {
        padding: 8px;
       font-size: small;
       flex: 1;
      }
}


