.PropertyReportChart .property-report-chart-container {
    height: 240px;
    width: 500px;
    margin-top: -20px;
    margin-bottom: -20px;
    overflow: visible;
  }
  @media (min-width: 576px) {
    .PropertyReportChart .property-report-chart-container {
      height: 240px;
      width: 500px;
    }
  }
  
  @media (min-width: 768px) {
    .PropertyReportChart .property-report-chart-container {
      height: 240px;
      width: 500px;
    }
  }
  
  @media (min-width: 992px) {
    .PropertyReportChart .property-report-chart-container {
      height: 240px;
      width: 500px;
    }
  }
  
  @media (min-width: 1200px) {
    .PropertyReportChart .property-report-chart-container {
      height: 240px;
      width: 500px;
    }
  }