@keyframes circletimer {
    0% {
      stroke-dashoffset: 500;
      stroke-dasharray: 500;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 500;
    }
  }
  .counter-svg {
    background-color: transparent;
    position: absolute;
    background-color: transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(-90deg);
  }
  .counter-svg .circle {
    stroke: var(--bs-primary);
    stroke-width: 1.5;
    fill: transparent;
    stroke-dashoffset: 500;
    stroke-dasharray: 0;
    animation:  circletimer 22s  linear  forwards;
  }
  
  .timer {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bs-primary);
    font-weight: 700;
  }