th,
td {
    white-space: nowrap;
}

.table-wrapper {
    border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  overflow-x: scroll;
  overflow-y: auto;
  max-height: 400px; 
  
  /* For WebKit (Chrome, Safari) */
  scrollbar-width: thin; 
  scrollbar-color: #888888 #f0f0f0;

  /* For other browsers */
  &::-webkit-scrollbar {
    width: 8px; 
    height: 8px; 
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888888; 
    width: 8px;
    height: 8px; 
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; 
  }
}

th:first-child,
td:first-child {
    position: sticky;
    left: -0.1em;
}

thead th {
    position: -webkit-sticky;
    position: sticky;
}

thead th:first-child {
    left: -0.1em;
    z-index: 2;
}
