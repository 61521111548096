@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200|Material+Icons+Round|Material+Icons+Two+Tone|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css?family=Inter:wght@300;400;500;600;700;800;900&display=swap");


@import "bootstrap";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  font-family: Inter, sans-serif;
  padding-top: 58px;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: 7px; 
}

body::-webkit-scrollbar-thumb {
  background-color: #888888; 
  width: 7px; 
}

body::-webkit-scrollbar-track {
  background-color: #f0f0f0; 
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 94vh;
 

  .Footer {
    flex: 0 0 auto;
    margin-bottom: -2px;
  }

  .MainContent,
  .Login,
  .ForgotPassword {
    flex: 1 0 auto;
  }

  .MainHeader {
    flex: 0 0 auto;
  }
}

@media (min-width: 992px) {
  .scroller {
    height: 94vh;
    position: sticky;
    top: 6vh;
    overflow: auto;
  }
}

.material-icons,
.material-icons-outlined {
  vertical-align: text-bottom;

  &.md-12 {
    font-size: 12px;
  }

  &.md-14 {
    font-size: 12px;
  }

  &.md-16 {
    font-size: 16px;
  }

  &.md-18 {
    font-size: 18px;
  }

  &.md-20 {
    font-size: 20px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-32 {
    font-size: 32px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  &.md-64 {
    font-size: 64px;
  }

  &.md-72 {
    font-size: 72px;
  }

  &.md-84 {
    font-size: 84px;
  }

  &.md-96 {
    font-size: 96px;
  }

  &.md-120 {
    font-size: 120px;
  }
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
}

.modal.left .modal-header,
.modal.right .modal-header,
.modal.left .modal-body,
.modal.right .modal-body,
.modal.right .modal-footer,
.modal.left .modal-footer {
  padding: 32px 32px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: 0px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
  left: 0px;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0px;
}

/* ----- MODAL STYLE ----- */
.modal.left .modal-content,
.modal.right .modal-content {
  border-radius: 0;
  border: none;
}

@media (min-width: 768px) {

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    width: 480px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    left: 0px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
  }
}

@media (min-width: 992px) {

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    width: 640px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    width: 0px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
  }
}

.dropdown {
  .dropdown-toggle::after {
    font-family: "Material Icons";
    font-size: 24px;
    color: inherit;
    vertical-align: middle;
    line-height: 1;
    margin: -2px 0 0 0;
    content: "\e5cf";
    border: none;
  }

  .dropdown-toggle.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.navbar-nav {
  .nav-item.dropdown.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.btn:focus {
  box-shadow: none;
}

.breadcrumb-item {
  a {
    text-decoration: none;
  }
}

// consider removing
.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.reportFontSize {
  font-size: 0.6em;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    background-color: white;
  }
}

@media print {
  .reportTable {
    page-break-before: always;
  }

  .reportContent {
    page-break-inside: avoid;
  }
}

@media print {
  .modal-body {
    height: 100%;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

.badge-color-primary {
  background-color: #0072CE !important
}

.badge-color-sucess {
  background-color: #6CC24A !important
}

.badge-color-warning {
  background-color: #F2A900 !important
}

.badge-color-danger {
  background-color: #E4002b !important
}

.placeholder-small-font-size::placeholder{
  font-size: 12px;
}

.custom-placeholder .placeholder {
  min-height: 0.7rem;
}

/* FileUpload.scss */

.select-control {
  position: relative;
}

.select-control select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  appearance: none; /* Remove default appearance */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="currentColor" d="M7 10l5 5 5-5z"></path></svg>'); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  background-size: 18px auto;
  padding-right: 30px; /* Adjust padding for the arrow */
}

.select-control select::-ms-expand {
  display: none; /* Hide default arrow in IE */
}

.select-control select:focus {
  outline: none;
  border-color: #4d90fe;
}

.text-wrap {
  word-wrap: break-word;
  max-width: 8rem; /* Adjust the max-width as needed */
}

.custom-dropdown {
  max-height: 600px; 
  overflow-y: auto; 
}
.custom-select {
  border-radius: 0.375rem;
}

.form-control-feedback {
  color: #dc3545;
}
